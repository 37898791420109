video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  justify-content: center;
  position: fixed;
  display: block;
  margin: 0 auto;
  pointer-events: none
}

.loading {
  width: 100%;
  height: 100%;
  object-fit: cover;
  justify-content: center;
  position: fixed;
  display: block;
  margin: auto;
  pointer-events: none;
  z-index: 1000;
  background-color: black;
}

.loading.hidden {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 1s, opacity 1s linear;
}

.loading.visible {
  visibility: visible;
  opacity: 1;
  transition: opacity 1s linear;
}
.loadingText {
  font-size: 200px;
  width: fit-content;
  height: fit-content;
  margin: auto;
  margin-top: 35vh;
  font-style: italic;
}



@media only screen and (max-width: 500px) {
  .loadingText {
    font-size: 50px;
  }
}

@media only screen and (max-height: 700px) {
  .loadingText {
    font-size: 50px;
  }

}




