.nameTitle {
  font-size: 100px;
  text-align: center;
}

.jobTitle {
  font-size: 30px;
}

.homeMenu {
  padding-top: 20px;
  font-size: 40px;
}

.profilePicture {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  border-radius: 50%;
}

.ppFrame {
  max-width: 500px;
  max-height: 500px;
  padding: 20px;
}
.icons {
  font-size: 50px;
}
.icon {
  margin: 10px;
  transition: hoverUnderline, 0.5s;
  color: white;
}

.icon:hover {
  color: #fa4da4;
  cursor: pointer;
}

.introText {
  text-align: center;
  font-style: italic;
  max-width: 600px;
  font-size: 25px;
  margin:2px;
}

@media only screen and (max-width: 500px) {
  .nameTitle {
    font-size: 35px;
  }
  .jobTitle {
    font-size: 15px;
  }
  .homeMenu {
    padding-top: 10px;
    font-size: 25px;
  }
  .profilePicture {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
    border-radius: 50%;
  }

  .ppFrame {
    max-width: 200px;
    max-height: 200px;
    padding: 20px;
  }
  .icon {
    font-size: 30px;
  }
  .introText {
    font-size: 15px;
    max-width: 300px;
  }
}

@media only screen and (max-height: 700px) {
  .nameTitle {
    font-size: 35px;
  }
  .jobTitle {
    font-size: 15px;
  }
  .homeMenu {
    padding-top: 10px;
    font-size: 18px;
  }
  .profilePicture {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
    border-radius: 50%;
  }

  .ppFrame {
    max-width: 175px;
    max-height: 175px;
    padding: 5px;
  }
  .icon {
    font-size: 30px;
  }
  .introText {
    font-size: 15px;
    max-width: 300px;
  }
}

@media only screen and (max-width: 950px) and (min-width: 700px) {
  .nameTitle {
    font-size: 35px;
  }
  .jobTitle {
    font-size: 15px;
  }
  .homeMenu {
    padding-top: 1px;
    font-size: 20px;
  }
  .icon {
    margin: 5px
  }
  .introText {
    font-size: 20px;
    max-width: 600px;
  }
}


@media only screen and (max-height: 1400px) and (min-height: 701px) {
  .nameTitle {
    font-size: 65px;
  }
  .jobTitle {
    font-size: 30px;
  }
  .homeMenu {
    padding-top: 10px;
    font-size: 35px;
  }
}

@media only screen and (max-height: 900px) and (max-width: 500px) {
  .nameTitle {
    font-size: 65px;
  }
  .jobTitle {
    font-size: 30px;
  }
  .homeMenu {
    padding-top: 10px;
    font-size: 20px;
  }
}
