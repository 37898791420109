.musicPlayerContainer {
  width: fit-content;
  height: fit-content;
  position: absolute;
  z-index: 100;
  background: rgba(0, 0, 0, 0.5);
  text-decoration: none;
  font-size: 30px;
  display: flex;
  border-radius: 10px;
  margin: 5px;
}

.mediaText {
  padding-left: 10px;
  font-size: 30px;
  text-align: center;
  margin-top: 2px;
}

.music {
  transition: hoverUnderline, 0.5s;
  padding: 2px;
  font-size: 35px;
  margin-left: 10px;

}

.music:hover {
  color: #fa4da4;
  cursor: pointer;
}

@media only screen and (max-height: 850px) {
  .mediaText {
    display: none;
  }
}

@media only screen and (max-width: 950px) and (min-width: 700px) {
  .mediaText {
    display: none;
  }
}
