.backButton {
  font-size: 35px;
  padding: 20px;
  padding-top: 0px;
}

.pageContent {
  font-size: 32.5px;
}

@media only screen and (max-height: 850px) {
  .backButton {
    font-size: 25px;
  }
  .pageContent {
    font-size: 15px;
  }
}

@media only screen and (max-width: 950px) and (min-width: 700px) {
  .pageContent {
    font-size: 20px;
  }
}

@media only screen and (max-height: 1400px) and (min-height: 851px) {
  .pageContent {
    font-size: 25px;
  }
}
