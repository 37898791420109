@keyframes hoverUnderline {
  from {
    text-decoration-color: white;
  }
  to {
    text-decoration-color: #fa4da4;
  }
}

.genericButton {
  text-decoration: underline;
  text-decoration-color: white;
  transition: hoverUnderline, 0.5s;
  padding: 2px;
  color: white;
}

.genericButton:hover {
  text-decoration-color: #fa4da4;
  cursor: pointer;
}
