.description {
    text-align: center;
    list-style-position: inside;
    font-size: 18px;
}

@media only screen and (max-width: 500px) {
    .description {
        font-size: 12px;
    }
  }

@media only screen and (max-height: 700px)  {
    .description {
        font-size: 9px;
    }
  }
  
  @media only screen and (max-width: 950px) and (min-width: 700px) and (max-width: 400px) {
    .description {
        font-size: 12px;
    }

  }
  
  @media only screen and (max-height: 1400px) and (min-height: 701px) and (max-width: 400px) {
    .description {
        font-size: 12px;
    }

  }