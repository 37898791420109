.title {
    font-size: 50px;
    text-decoration: underline;
}

@media only screen and (max-width: 500px) {
    .title {
        font-size: 25px;
    }
  }

@media only screen and (max-height: 700px)  {
    .title {
        font-size: 18px;
    }
  }
  
  @media only screen and (max-width: 950px) and (min-width: 700px) and (max-width: 400px) {
    .title {
        font-size: 25px;
    }


  }
  
  @media only screen and (max-height: 1400px) and (min-height: 701px) and (max-width: 400px) {
    .title {
        font-size: 25px;
    }
    .jobTitle {
        font-size: 20px;
    }

  }