.section {
    padding: 10px;
}

.jobTitle {
font-size: 32px;
}

.jobContent {
    font-size: 22px;
}

.skills {
    text-align: center;
    margin-right: 10%;
    list-style-position: inside;
    font-size: 18px;
}

@media only screen and (max-width: 500px) {
    .section {
        padding: 2px;
    }
    .jobTitle {
        font-size: 20px;
    }
    .jobContent {
        font-size: 15px;
    }
    .skills {
        font-size: 12px;
        text-align: left;
    }
  }

@media only screen and (max-height: 700px)  {
    .section {
        padding: 0px;
    }
    .jobTitle {
        font-size: 16px;
    }
    .jobContent {
        font-size: 11px;
    }
    .skills {
        font-size: 9px;
        text-align: left;
    }
  }
  
  @media only screen and (max-width: 950px) and (min-width: 700px) and (max-width: 400px) {
    .section {
        padding: 2px;
    }
    .jobTitle {
        font-size: 20px;
    }
    .jobContent {
        font-size: 15px;
    }
    .skills {
        font-size: 12px;
        text-align: left;
    }

  }
  
  @media only screen and (max-height: 1400px) and (min-height: 701px) and (max-width: 400px) {
    .section {
        padding: 2px;
    }
    .jobTitle {
        font-size: 20px;
    }
    .jobContent {
        font-size: 10px;
    }
    .skills {
        font-size: 12px;
        text-align: left;
    }

  }